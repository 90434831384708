import ThemeOptions from './ThemeOptions';
import Users from './user';
import Tickets from './tickets';
import Services from './services';
import Errors from './errors';
import Specialists from './specialists';

export default {
  ThemeOptions,
  Users,
  Services,
  Tickets,
  Errors,
  Specialists
};
