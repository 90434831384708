import Base from './base';

class User extends Base {

  me() {
    return this.get('operator/me');
  }

  login(data) {
    return this.post('operator/login', data)
  }

  list() {
    return this.get('operator/list', {});
  }

  edit(data) {
    return this.upload('operator/me/update', data);
  }

  register(data) {
    return this.upload('operator/register', data);
  }

  editUser(data) {
    return this.upload('operator/user/update', data);
  }

  getUser(userId) {
    return this.get(`operator/user/${userId}`);
  }

}

export default new User();
