import React, { Fragment } from 'react';

import { Button } from '@material-ui/core';

const HeaderMenu = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'mega-menu-popover' : undefined;

  const [anchorElMenu, setAnchorElMenu] = React.useState(null);

  const handleClickMenu = event => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  return (
    <Fragment>
      <div className="">
        <Button
          href={'/dashboard'}
          color="inherit"
          size="medium"
          className="btn-inverse font-size-xs mr-3">
          Dashboard
        </Button>
        {props.user && props.user.role === 'admin' && (
          <Button
            href={'users'}
            size="medium"
            color="inherit"
            className="btn-inverse font-size-xs mx-2">
            Users
          </Button>
        )}
        {props.user && props.user.role === 'admin' && (
          <Button
            href={'specialists'}
            size="medium"
            color="inherit"
            className="btn-inverse font-size-xs mx-2">
            Specialists
          </Button>
        )}
        {props.user &&
          (props.user.role === 'admin' || props.user.role === 'operator') && (
            <Button
              href={'report'}
              size="medium"
              color="inherit"
              className="btn-inverse font-size-xs mx-2">
              Report
            </Button>
          )}
      </div>
    </Fragment>
  );
};

export default HeaderMenu;
