import { Specialist, User} from '../vendors/services/api';

let { REACT_APP_API_URL } = process.env;
if (!REACT_APP_API_URL) {
  REACT_APP_API_URL = 'https://mapleleafsclinic.xyz/api/v1/';
}
console.log('REACT_APP_API_URL-2', REACT_APP_API_URL);

const Initials = name => {
  if (!name) return '';
  const initials = name.match(/\b\w/g) || [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};

const generateAvatarUrl = filename =>
  `${REACT_APP_API_URL.replace(
    '/api/v1',
    ''
  )}public/operator/avatars/${filename}`;

export const getSpecialistInfo = () => {
  return async dispatch => {
    try {
      const payload = await User.me();
      payload.avatar = payload.avatar
        ? generateAvatarUrl(payload.avatar)
        : null;
      payload.initials = Initials(`${payload.first_name_en} ${payload.last_name_en}`);
      dispatch({ type: 'GET_SPECIALIST_INFO', payload });
      if (payload.role === 'admin') {
        const list = (await Specialist.list()).map(val => {
          val.avatar = val.avatar ? generateAvatarUrl(val.avatar) : null;
          val.initials = Initials(`${val.first_name_en} ${val.last_name_en}`);
          return val;
        });
        dispatch({ type: 'ALL_SPECIALISTS', payload: list });
      }
    } catch (e) {
      localStorage.removeItem('token');
      if (window.location.pathname !== '/login') {
        window.location.href = '/';
      }
      return dispatch({ type: 'SET_ERROR', payload: e });
    }
  };
};

export const getSpecialistsList = () => {
  return async dispatch => {
    try {
      const list = (await Specialist.list()).map(val => {
        val.avatar = val.avatar ? generateAvatarUrl(val.avatar) : null;
        val.initials = Initials(`${val.first_name_en} ${val.last_name_en}`);
        return val;
      });
      dispatch({ type: 'ALL_SPECIALISTS', payload: list });
    } catch (e) {
      return dispatch({ type: 'SET_ERROR', payload: e });
    }
  };
};

export const getSpecialist = userId => {
  return async dispatch => {
    try {
      const payload = await Specialist.getSpecialist(userId);
      payload.avatar = payload.avatar
        ? generateAvatarUrl(payload.avatar)
        : null;
      payload.initials = Initials(`${payload.first_name_en} ${payload.last_name_en}`);
      dispatch({ type: 'SINGLE_SPECIALIST', payload });
    } catch (e) {
      return dispatch({ type: 'SET_ERROR', payload: e });
    }
  };
};

export const login = data => async dispatch => {
  try {
    const payload = await Specialist.login(data);
    payload.avatar = payload.avatar ? generateAvatarUrl(payload.avatar) : null;
    payload.initials = Initials(`${payload.first_name_en} ${payload.last_name_en}`);
    localStorage.setItem('token', payload.token);
    window.location.href = '/dashboard';
    return dispatch({ type: 'GET_SPECIALIST_INFO', payload });
  } catch (e) {
    return dispatch({ type: 'SET_ERROR', payload: e });
  }
};

export const edit = data => async dispatch => {
  try {
    const payload = (await Specialist.edit(data)).data;
    payload.avatar = payload.avatar ? generateAvatarUrl(payload.avatar) : null;
    payload.initials = Initials(`${payload.first_name_en} ${payload.last_name_en}`);
    return dispatch({ type: 'GET_SPECIALIST_INFO', payload });
  } catch (e) {
    return dispatch({ type: 'SET_ERROR', payload: e });
  }
};

export const createSpecialist = data => async dispatch => {
  try {
    await Specialist.registerSpecialist(data);
    return (window.location.href = '/specialists');
  } catch (e) {
    return dispatch({ type: 'SET_ERROR', payload: e });
  }
};

export const editSpecialist = data => async dispatch => {
  try {
    await Specialist.editSpecialist(data);
    return (window.location.href = '/specialists');
  } catch (e) {
    return dispatch({ type: 'SET_ERROR', payload: e });
  }
};

export default function reducer(
  state = {
    specialist: null,
    specialists: [],
    singleSpecialist: null,
    error: null
  },
  action
) {
  switch (action.type) {
    // Sidebar

    case 'GET_SPECIALIST_INFO':
      return {
        ...state,
        specialist: action.payload
      };
    case 'SINGLE_SPECIALIST':
      return {
        ...state,
        singleSpecialist: action.payload
      };
    case 'ALL_SPECIALISTS':
      return {
        ...state,
        specialists: action.payload
      };
    case 'GET_SPECIALIST_ERROR':
      return {
        ...state,
        error: action.payload
      };
    default:
      break;
  }
  return state;
}
