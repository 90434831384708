import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {getUserInfo} from '../../reducers/user'
import {ClimbingBoxLoader} from 'react-spinners';

import PagesLoginContent from '../../components/PagesLogin/PagesLoginContent';
import _isEqual from 'lodash/isEqual';


class Login extends React.Component {
  state = {
    userIsLoggedIn: false,
    user: null,
    loading: true
  };

  componentDidMount() {
    this.props.getUserInfo();
  }

  static getDerivedStateFromProps(props, state) {
    const {user, error, history} = props;
    if (!error && user) {
      history.push('/dashboard');
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      return {
        userIsLoggedIn: true,
        user,
        loading: false
      }
    }
    if ((error && !user) || (!error && !user)) {
      return {
        loading: false
      }
    }

    return null;
  }

  render() {
    const {userIsLoggedIn, user, loading} = this.state;
    return (
      <Fragment>
        {loading && <Loader/>}
        {!user && !loading && <PagesLoginContent/>}
      </Fragment>
    );
  }
}

const Loader = () => {
  return (
    <Fragment>
      <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
        <div className="d-flex align-items-center flex-column px-4">
          <ClimbingBoxLoader color={'#5383ff'} loading={true}/>
        </div>
        <div className="text-muted font-size-xl text-center pt-3">
          Please wait system is loading
          <span className="font-size-lg d-block text-dark">
             It might take several seconds.
          </span>
        </div>
      </div>
    </Fragment>
  );
};




const mapStateToProps = (state) => {
  return {
    user: state.Users.user,
    error: state.Users.error,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    getUserInfo: () => dispatch(getUserInfo())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);



