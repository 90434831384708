import React, {Fragment} from 'react';
import {
  Grid,
  Container,
  Input,
  InputLabel,
  Card,
  CardContent,
  Button,
  FormControl
} from '@material-ui/core';
import {connect} from "react-redux";

import {
  login
} from '../../../reducers/user';

import svgImage9 from '../../../assets/images/illustrations/login.svg';


const LoginPage = (props) => {
  const [form, setCredentials] = React.useState({
    username: '',
    password: ''
  });


  const handleChange = (event, field) => {
    setCredentials({
      ...form,
      [field]: event.target.value
    });
  };

  const submit = () => {
    props.userLogin(form)
  };

  return (
    <Fragment>
      <div className="app-wrapper min-vh-100">
        <div className="app-main flex-column">
          <div className="app-content p-0">
            <div className="app-content--inner d-flex align-items-center">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content py-5">
                  <Container maxWidth="lg">
                    <Grid container spacing={5} className={'justify-content-center'}>
                      <Grid
                        item
                        xs={12}
                        lg={5}
                        className="d-none d-xl-flex align-items-center">
                        <img
                          alt="..."
                          className="w-100 mx-auto d-block img-fluid"
                          src={svgImage9}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={7}
                        className="d-flex flex-column align-items-center">
                        <span className="w-100 text-left text-md-center pb-4">
                          <h1 className="display-3 text-xl-left text-center mb-3 font-weight-bold">
                            Clinic Maple Leafs Admin
                          </h1>
                          <p className="font-size-lg text-xl-left text-center mb-0 text-black-50">
                            Login to your account
                          </p>
                        </span>
                        <Card className="m-0 w-100 p-0 border-0">
                          <CardContent className="p-3">
                            <form className="px-5">
                              <div className="mb-3">
                                <FormControl className="w-100">
                                  <InputLabel htmlFor="input-with-icon-adornment">
                                    Username
                                  </InputLabel>
                                  <Input
                                    fullWidth
                                    onChange={(e) => handleChange(e, 'username')}
                                    id="input-with-icon-adornment"
                                  />
                                </FormControl>
                              </div>
                              <div className="mb-3">
                                <FormControl className="w-100">
                                  <InputLabel htmlFor="standard-adornment-password">
                                    Password
                                  </InputLabel>
                                  <Input
                                    id="standard-adornment-password"
                                    fullWidth
                                    onChange={(e) => handleChange(e, 'password')}
                                    type="password"
                                  />
                                </FormControl>
                              </div>
                              <div className="text-center">
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={submit}
                                  size="large"
                                  className="my-2">
                                  Sign in
                                </Button>
                              </div>
                            </form>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  const {User, UserLoginForm} = state;
  return {
    User,
    UserLoginForm
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    userLogin: (data) => dispatch(login(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
