import {Tickets} from '../vendors/services/api';

let {REACT_APP_API_URL} = process.env;
if(!REACT_APP_API_URL){
  REACT_APP_API_URL = 'https://mapleleafsclinic.xyz/api/v1/';
}
const Initials = (name) => {
  if (!name) return '';
  const initials = name.match(/\b\w/g) || [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};

const generateAvatarUrl = filename => `${REACT_APP_API_URL.replace('/api/v1', '')}public/operator/avatars/${filename}`;

const serialize = data => data.map((val, key) => {
  if (val.operator) {
    val.operator.initials = Initials(`${val.operator.first_name} ${val.operator.last_name}`);
    val.operator.avatar = val?.operator?.avatar ? generateAvatarUrl(val.operator.avatar) : null;
  }
  if (val.user) {
    val.user.initials = Initials(`${val.user.first_name} ${val.user.last_name}`);
    val.user.avatar = val?.user?.avatar ? generateAvatarUrl(val.user.avatar) : null;
  }
  return val;
});


export const pending = () => {
  return async (dispatch) => {
    try {
      const payload = serialize(await Tickets.list({status: 'pending'}));
      dispatch({type: 'RESET_FILTER'})
      return dispatch({type: 'PENDING_TICKETS', payload});
    } catch (e) {
      return dispatch({type: 'PENDING_TICKETS_ERROR', payload: e})
    }
  }
};

export const inProcess = () => {
  return async (dispatch) => {
    try {
      const payload = serialize((await Tickets.list({status: 'in_process'})));
      dispatch({type: 'RESET_FILTER'})
      return dispatch({type: 'IN_PROCESS_TICKETS', payload})
    } catch (e) {
      return dispatch({type: 'SET_ERROR', payload: e})
    }
  }
};

export const single = (ticket_id) => {
  return async (dispatch) => {
    try {
      const payload = await Tickets.single(ticket_id);
      payload.messages = serialize(payload.messages);
      dispatch({type: 'RESET_FILTER'})
      return dispatch({type: 'SINGLE_TICKET', payload: payload})
    } catch (e) {
      return dispatch({type: 'SET_ERROR', payload: e})
    }
  }
};

export const filter = (props) => {
  return async (dispatch) => {
    try {
      const {query, type} = props;
      return dispatch({type: 'FILTERED_TICKETS', payload: {query, type}})

    } catch (e) {
      return dispatch({type: 'SET_ERROR', payload: e})
    }
  }
};

export const assign = (ticket_id) => {
  return async (dispatch) => {
    try {
      await Tickets.assign(ticket_id);
      const pending = serialize((await Tickets.list({status: 'pending'})));
      dispatch({type: 'PENDING_TICKETS', payload: pending});

      const inProcess = serialize((await Tickets.list({status: 'in_process'})));
      return dispatch({type: 'IN_PROCESS_TICKETS', payload: inProcess})

    } catch (e) {
      return dispatch({type: 'SET_ERROR', payload: e})
    }
  }
};


export const closed = () => {
  return async (dispatch) => {
    try {
      const payload = serialize((await Tickets.list({status: 'closed'})));
      dispatch({type: 'RESET_FILTER'})
      return dispatch({type: 'CLOSED_TICKETS', payload})
    } catch (e) {
      return dispatch({type: 'SET_ERROR', payload: e})
    }
  }
};

export const submitMessage = (data) => {
  return async (dispatch) => {
    const payload = (serialize([await Tickets.postMessage(data)]))[0];
    return dispatch({type: 'PUSH_MESSAGE', payload: payload})
  }
};

export const closeTicket = (data) => {
  return async (dispatch) => {
    try {
      return await Tickets.closeTicket(data);
    } catch (e) {
      dispatch({type: 'SET_ERROR', payload: e});
      return e;
    }
  }
};


export default function reducer(state = {
  pending: [],
  inProcess: [],
  closed: [],
  filtered: [],
  single: null,
  error: null
}, action) {
  switch (action.type) {
    // Sidebar

    case 'PENDING_TICKETS':
      return {
        ...state,
        pending: action.payload
      };
    case 'IN_PROCESS_TICKETS':
      return {
        ...state,
        inProcess: action.payload
      };
    case 'CLOSED_TICKETS':
      return {
        ...state,
        closed: action.payload
      };
    case 'SINGLE_TICKET':
      return {
        ...state,
        single: action.payload
      };
    case 'RESET_FILTER':
      return {
        ...state,
        filtered: {
          query:null,
          result:[]
        }
      };
    case 'FILTERED_TICKETS':

      const {query, type} = action.payload;
      const filtered = query ? state[type].filter(v => {
        return v.operator && (
          v.operator.first_name.toLowerCase().includes(query.trim().toLowerCase()) ||
          v.operator.last_name.toLowerCase().includes(query.trim().toLowerCase())
        )
      }) : [];
      return {
        ...state,
        filtered: {
          query,
          result:filtered
        }
      };
    case 'PUSH_MESSAGE':
      state.single.messages.push(action.payload);
      return {
        ...state
      };
    default:
      break;
  }
  return state;
}
