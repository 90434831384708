import React, { lazy, Fragment, Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { ThemeProvider } from '@material-ui/styles';

import { ClimbingBoxLoader } from 'react-spinners';

import MuiTheme from './theme';

// Layout Blueprints

import { LeftSidebar } from './layout-blueprints';
import { createBrowserHistory } from 'history';

// Example Pages

import PagesLogin from './pages/PagesLogin';

const Tickets = lazy(() => import('./pages/tickets'));
const Users = lazy(() => import('./pages/users'));
const Specialists = lazy(() => import('./pages/specialists'));

const Me = lazy(() => import('./pages/me'));

const createUser = lazy(() => import('./pages/createUser'));
const createSpecialist = lazy(() => import('./pages/createSpecialist'));

const editUser = lazy(() => import('./pages/editUser'));
const editSpecialist = lazy(() => import('./pages/editSpecialist'));

const report = lazy(() => import('./pages/report'));

const ApplicationsChat = lazy(() => import('./pages/ApplicationsChat'));

const history = createBrowserHistory();
const Routes = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Redirect exact from="/" to="/login" />
            <Route path="/login" component={PagesLogin} />
            <Route
              history={history}
              path={[
                '/dashboard',
                '/users',
                '/specialists',
                '/create-user',
                '/create-specialist',
                '/chat',
                '/me',
                '/edit-user',
                '/edit-specialist',
                '/report'
              ]}>
              <LeftSidebar history={history}>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/dashboard" component={Tickets} />
                    <Route path="/users" component={Users} />
                    <Route path="/specialists" component={Specialists} />
                    <Route path="/report" component={report} />
                    <Route path="/chat/:id" component={ApplicationsChat} />
                    <Route path="/me" component={Me} />
                    <Route path="/create-user" component={createUser} />
                    <Route
                      path="/create-specialist"
                      component={createSpecialist}
                    />
                    <Route path="/edit-user/:id" component={editUser} />
                    <Route
                      path="/edit-specialist/:id"
                      component={editSpecialist}
                    />
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

const Loader = () => {
  return (
    <Fragment>
      <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
        <div className="d-flex align-items-center flex-column px-4">
          <ClimbingBoxLoader color={'#5383ff'} loading={true} />
        </div>
        <div className="text-muted font-size-xl text-center pt-3">
          Please wait system is loading
          <span className="font-size-lg d-block text-dark">
            It might take several seconds.
          </span>
        </div>
      </div>
    </Fragment>
  );
};

export default Routes;
