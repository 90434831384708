import Base from './base';

class Static extends Base {

  list() {
    return this.get('static/services');
  }

  report(data){
    return this.post('operator/report', data);
  }

}

export default new Static();
