export const resetError = () => {
  return (dispatch) => dispatch({type: 'RESET_ERROR'})
};


export const setError = (payload) => {
  return (dispatch) => dispatch({type: 'SET_ERROR', payload})
};

export default function reducer(state = {error: null}, action) {
  switch (action.type) {
    // Sidebar
    case 'SET_ERROR':
      const payload = action?.payload?.response?.data || action.payload;
      return {
        ...state,
        error:payload
      };
    case 'RESET_ERROR':
      return {
        ...state,
        error: null
      };
    default:
      break;
  }
  return state;
}
