/**
 * HOST config comes from webpack and it's not defined
 */

import axios from "axios";


class AxiosInstance {

    static create(hostType = 'default', widthHeaders = false) {
        const {REACT_APP_API_URL} = process.env;
        const config = {
            baseURL: REACT_APP_API_URL || 'api/v1', // all requests are routed to /api/something....
            timeout: 60000, // default timeout of 60 seconds
            //withCredentials: true,
        };

        const axiosInstance = axios.create(config);


        // before the request is sent to the server, add jwt to the Authorization header
        axiosInstance.interceptors.request.use(config => {
            const token = localStorage.getItem('token');
            if (token) {
                config.headers['authorization'] = `Bearer ${token.replace('%20', ' ')}`;
            }
            return config;
        });

        // whenever a response is received from the node layer
        axiosInstance.interceptors.response.use(response => {
            if (widthHeaders) {
                return {
                    data: response.data,
                    headers: response.headers
                }
            }
            return response.data;
        }, error => {
            //console.warn('request error', error);
            return Promise.reject(error.response ? {
                code: error.response.status,
                data: error.response.data
            } : {message: error});
        });
        return axiosInstance;
    }

}


export default AxiosInstance
