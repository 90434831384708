import {User} from '../vendors/services/api'

let {REACT_APP_API_URL} = process.env;
if(!REACT_APP_API_URL){
  REACT_APP_API_URL = 'https://mapleleafsclinic.xyz/api/v1/';
}
console.log('REACT_APP_API_URL-2', REACT_APP_API_URL);

const Initials = (name) => {
  if (!name) return '';
  const initials = name.match(/\b\w/g) || [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};

const generateAvatarUrl = filename => `${REACT_APP_API_URL.replace('/api/v1', '')}public/operator/avatars/${filename}`;

export const getUserInfo = () => {
  return async (dispatch) => {
    try {
      const payload = await User.me();
      payload.avatar = payload.avatar ? generateAvatarUrl(payload.avatar) : null;
      payload.initials = Initials(`${payload.first_name} ${payload.last_name}`);
      dispatch({type: 'GET_USER_INFO', payload});
      if (payload.role === 'admin' || payload.role === 'operator') {
        const list = (await User.list()).map((val) => {
          val.avatar = val.avatar ? generateAvatarUrl(val.avatar) : null;
          val.initials = Initials(`${val.first_name} ${val.last_name}`);
          return val;
        });
        dispatch({type: 'ALL_USERS', payload: list})
      }
    } catch (e) {
      localStorage.removeItem('token');
      if (window.location.pathname !== '/login') {
        window.location.href = '/';
      }
      return dispatch({type: 'SET_ERROR', payload: e})
    }
  }
};

export const getUser = (userId) => {
  return async (dispatch) => {
    try {
      const payload = await User.getUser(userId);
      payload.avatar = payload.avatar ? generateAvatarUrl(payload.avatar) : null;
      payload.initials = Initials(`${payload.first_name} ${payload.last_name}`);
      dispatch({type: 'SINGLE_USER', payload});
    } catch (e) {
      return dispatch({type: 'SET_ERROR', payload: e})
    }
  }
};

export const login = (data) => async dispatch => {
  try {
    const payload = await User.login(data);
    payload.avatar = payload.avatar ? generateAvatarUrl(payload.avatar) : null;
    payload.initials = Initials(`${payload.first_name} ${payload.last_name}`);
    localStorage.setItem('token', payload.token);
    window.location.href = '/dashboard';
    return dispatch({type: 'GET_USER_INFO', payload});
  } catch (e) {
    return dispatch({type: 'SET_ERROR', payload: e})
  }
};

export const edit = (data) => async dispatch => {
  try {
    const payload = (await User.edit(data)).data;
    payload.avatar = payload.avatar ? generateAvatarUrl(payload.avatar) : null;
    payload.initials = Initials(`${payload.first_name} ${payload.last_name}`);
    return dispatch({type: 'GET_USER_INFO', payload});
  } catch (e) {
    return dispatch({type: 'SET_ERROR', payload: e})
  }
};

export const createUser = (data) => async dispatch => {
  try {
    await User.register(data);
    return window.location.href = '/users';
  } catch (e) {
    return dispatch({type: 'SET_ERROR', payload: e})
  }
};

export const editUser = (data) => async dispatch => {
  try {
    await User.editUser(data);
    return window.location.href = '/users';
  } catch (e) {
    return dispatch({type: 'SET_ERROR', payload: e})
  }
};

export default function reducer(state = {user: null, users: [], singleUser: null, error: null}, action) {
  switch (action.type) {
    // Sidebar

    case 'GET_USER_INFO':
      return {
        ...state,
        user: action.payload
      };
    case 'SINGLE_USER':
      return {
        ...state,
        singleUser: action.payload
      };
    case 'ALL_USERS':
      return {
        ...state,
        users: action.payload
      };
    case 'GET_USER_ERROR':
      return {
        ...state,
        error: action.payload
      };
    default:
      break;
  }
  return state;
}
