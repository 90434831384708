import React, {Fragment} from 'react';

import {IconButton, Button} from '@material-ui/core';

import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import {resetError} from "../../reducers/errors";
import {connect} from "react-redux";
import { makeStyles } from '@material-ui/core/styles';


const dashboardRedirectErrors = ['400_5'];


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CmlSnackBack = (props) => {
  const {Errors} = props;

  const error = Errors.error;
  const [open, setOpen] = React.useState(!!error);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    if (dashboardRedirectErrors.includes(error?.error_id || error?.data?.error_id)) {
      window.location.href = '/dashboard'
    }
    setOpen(false);
    props.resetError();
  };
  if (!open && !!error) {
    setOpen(true);
  }
  if (open && !error) {
    setOpen(false);
  }
  return (
    <Fragment>
      <div className="text-center">
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {error?.message || error?.data?.message}
          </Alert>
        </Snackbar>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  const {Errors} = state;
  return {
    Errors
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    resetError: () => dispatch(resetError()),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CmlSnackBack);
