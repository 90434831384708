import Base from './base';

class Specialist extends Base {
  me() {
    return this.get('specialist/me');
  }

  login(data) {
    return this.post('specialist/login', data);
  }

  list() {
    return this.get('specialist/list', {});
  }

  edit(data) {
    return this.upload('specialist/me/update', data);
  }

  registerSpecialist(data) {
    return this.upload('specialist/register', data);
  }

  editSpecialist(data) {
    return this.upload('specialist/user/update', data);
  }

  getSpecialist(userId) {
    return this.get(`specialist/user/${userId}`);
  }
}

export default new Specialist();
