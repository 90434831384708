import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {connect} from 'react-redux';

import {Sidebar, Header, Footer} from '../../layout-components';
import Snackbar from '../../components/snackbar/snackbar'

const LeftSidebar = props => {
  const {
    children,
    sidebarFixed,
    contentBackground
  } = props;
  return (
    <Fragment>
      <div className={clsx('app-wrapper', contentBackground)}>
        <Header/>
        <div
          className={clsx('app-main', {
            'app-main-sidebar-static': !sidebarFixed
          })}>
          <div
            className={clsx('app-content', {})}>
            {props.history.location.pathname.includes("chat") ?
              <div className="app-inner-content-layout--main">{children}</div> : <div className="app-content--inner">
                <div className="app-content--inner__wrapper">{children}</div>
              </div>}

          </div>
          <Snackbar history={props.history}/>
        </div>
      </div>
    </Fragment>
  );
};

LeftSidebar.propTypes = {
  children: PropTypes.node
};

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.ThemeOptions.sidebarFixed,

  headerFixed: state.ThemeOptions.headerFixed,
  headerSearchHover: state.ThemeOptions.headerSearchHover,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,

  footerFixed: state.ThemeOptions.footerFixed,

  contentBackground: state.ThemeOptions.contentBackground
});

export default connect(mapStateToProps)(LeftSidebar);
