import {Static} from '../vendors/services/api'

export const getServices = () => {
  return async (dispatch) => {
    try {
      const payload = await Static.list();
      return dispatch({type: 'SERVICES', payload})
    } catch (e) {
      return dispatch({type: 'SET_ERROR', payload: e})
    }
  }
};

export const getReport = (data) => {
  return async (dispatch) => {
    try {
      const payload = await Static.report(data);
      return dispatch({type: 'REPORT', payload})
    } catch (e) {
      return dispatch({type: 'SET_ERROR', payload: e})
    }
  }
};


export default function reducer(state = {services: {}, report: {}, error: null}, action) {
  switch (action.type) {
    // Sidebar

    case 'SERVICES':
      return {
        ...state,
        services: action.payload
      };
    case 'REPORT':
      return {
        ...state,
        report: action.payload
      };
    case 'GET_SERVICES_ERROR':
      return {
        ...state,
        error: action.payload
      };
    default:
      break;
  }
  return state;
}
