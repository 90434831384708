import Base from './base';

class Tickets extends Base {

  list(data) {
    return this.get('tickets/operator', data);
  }

  single(ticket_id){
    return this.get(`tickets/operator/${ticket_id}`)
  }

  postMessage(data){
    return this.post('/tickets/message/operator', data)
  }

  closeTicket(data){
    return this.post('tickets/close', data)
  }

  assign(data){
    return this.post('tickets/assign', data)
  }

}

export default new Tickets();
