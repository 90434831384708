import Axios from './axiosWrapper';
import axios from "axios";


class BaseApi {

    constructor(hostType = 'default') {
        this.axios = Axios.create(hostType);
        this.axiosWithHeaders = Axios.create(hostType, true);
        this.serializer = this.serializer.bind(this)
    }

    post(url, data = {}, headers = {}) {
        return this.req('post', url, data, headers)
    }

    upload(url, data = {}, auth = true) {
        const {REACT_APP_API_URL} = process.env;
        const token = localStorage.getItem('token');
        if (!token && auth) return null;
        return axios({
            method: 'post',
            url: `${REACT_APP_API_URL || 'api/v1/'}${url}`,
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data',
                'authorization': auth ? `Bearer ${token?.replace('%20', ' ')}` : ''
            }
        }, { responseType: 'json' });
    }

    delete(url, data = {}, withHeaders = false) {
        return this.req('delete', url, data, withHeaders)
    }

    put(url, data = {}, withHeaders = false) {
        return this.req('put', url, data, withHeaders)
    }

    get(url, data = {}, withHeaders = false) {
        return this.req('get', url, data, withHeaders)
    }

    async req(method, url, data = {}, headers = {}) {
        if (!method)
            throw new Error('Method is required');
        if (!url)
            throw new Error('URL is required');

        /*if (cancelToken) {
            Object.assign(data, {cancelToken: cancelToken.token});
        }*/

        const reqData = {};
        if (method === 'get') {
            Object.assign(reqData, {params: data});
        } else {
            Object.assign(reqData, {...data});
        }
        const resp = await this.axios[method](url, reqData, headers);
        return this.serializer(resp);
    }

    serializer(response) {
        if (response.status && response.status === 'error') {
            throw response
        }
        return response || false;
    }
}

export default BaseApi
