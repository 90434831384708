import React, {Fragment} from 'react';

import clsx from 'clsx';
import {Link} from 'react-router-dom';

import {Hidden, IconButton, AppBar, Box, Tooltip} from '@material-ui/core';
import Users, {getUserInfo} from '../../reducers/user';
import Specialist, {getSpecialistInfo} from '../../reducers/specialists';
import {
  getServices
} from '../../reducers/services';
import {connect} from 'react-redux';

import {
  setSidebarToggle,
  setSidebarToggleMobile
} from '../../reducers/ThemeOptions';
import projectLogo from '../../assets/images/react.svg';

import HeaderLogo from '../../layout-components/HeaderLogo';
import HeaderDots from '../../layout-components/HeaderDots';
import HeaderDrawer from '../../layout-components/HeaderDrawer';
import HeaderUserbox from '../../layout-components/HeaderUserbox';
import HeaderSearch from '../../layout-components/HeaderSearch';
import HeaderMenu from '../../layout-components/HeaderMenu';

import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';

const Header = props => {
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  if (!Object.keys(props.services.services).length) {
    props.getServices();
  }
  if (!props.user) {
    props.getUser();
  }
  const {
    headerShadow,
    headerFixed,
    sidebarToggleMobile,
    setSidebarToggleMobile,
    setSidebarToggle,
    sidebarToggle
  } = props;

  return (
    <Fragment>
      <AppBar
        color="secondary"
        className={clsx('app-header', {
          'app-header-collapsed-sidebar': props.isCollapsedLayout
        })}
        position={headerFixed ? 'fixed' : 'absolute'}
        elevation={headerShadow ? 11 : 3}>
        {!props.isCollapsedLayout && <HeaderLogo/>}
        <Box className="app-header-toolbar">
          <Hidden lgUp>
            <Box
              className="app-logo-wrapper"
              title="Clinic Maple Leafs">
              <Link to="/dashboard" className="app-logo-link">
                <IconButton
                  color="primary"
                  size="medium"
                  className="app-logo-btn">
                  <img
                    className="app-logo-img"
                    style={{
                      borderRadius:'50px',
                      width:'50px',
                      height:'50px'
                    }}
                    alt="Clinic Maple leafs"
                    src={'http://mapleleafsclinic.com/wp-content/uploads/2015/11/maple_leafs_clinics-logo.jpg'}
                  />
                </IconButton>
              </Link>
            </Box>
          </Hidden>
          {props.user && <Box className="d-flex align-items-center">
            <HeaderMenu user={props.user}/>
          </Box>}
          <Box className="d-flex align-items-center">
            {/*
            <HeaderDots />
*/}
            {props.user && <HeaderUserbox user={props.user}/>}
          </Box>
        </Box>
      </AppBar>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  user: state.Users.user,
  services: state.Services
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggle: enable => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
  getUser: () => dispatch(getUserInfo()),
  getServices: () => dispatch(getServices())
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
